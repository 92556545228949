import axios from "axios";

const config = {
  baseURL: process.env.NODE_ENV === 'production' ? "https://emailsender.singlec.com.br/api/" : 'http://localhost:4002/api'
};

const axiosConfig = axios.create(config);

axiosConfig.defaults.headers["Content-Type"] = "application/json";

export const api = axiosConfig;
