import '../assets/css/style.css'
import '../assets/css/info.css'
import React from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InfoImg from '../assets/img/info-img.png'
import icon from '../assets/img/check2.png'

function ComponentInfo(){
    return(
        <Container>
            <div id="info">
                <Row>
                    <Col lg="5" md="12" sm="12" xs="12">
                        <div id="info-x1">
                            <ul>
                                <h1>Nossos diferenciais</h1>
                                <li>
                                    <img src={icon} alt=""/>
                                    <p>Logística eficiente em escala global</p>
                                </li>
                                <li>
                                    <img src={icon} alt="" />
                                    <p>Atendimento personalizado e transparente</p>    
                                </li>
                                <li>
                                    <img src={icon} alt="" />
                                    <p>Flexibilidade operacional</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="7" md="12" sm="12" xs="12"> 
                        <div id="info-x2">
                            <img src={InfoImg} alt="" />
                        </div>
                    </Col>
                </Row>                
            </div>
         </Container>
    )
}
export default ComponentInfo;