import '../assets/css/style.css';
import Header from "../layout/Header";
import About from '../layout/About.jsx';
import Services from '../layout/Services.jsx';
import Info from '../layout/Info.jsx';
import Contact from '../layout/Contact.jsx';
import Footer from '../layout/Footer.jsx';
import Wapp from '../components/Wapp';
import Operations from '../layout/Operations.jsx';  
import { EmailSentProvider } from "../context/EmailSentContext.jsx";
import EmailSentModal from "../components/EmailSentModal.jsx";

function Home(){
    return(
        <EmailSentProvider>
            <section id="header">
                <Header></Header>
            </section>
            <section  id="about">  
                <About></About>
            </section>
            <section id="services">
                <Services></Services>
            </section>
            <section id="operations">
                <Operations></Operations>
            </section>
            <section id="info">
                <Info></Info>
            </section>
            <section id="contact">
                <Contact></Contact>
            </section>
            <section id="footer">
                <Footer></Footer>
            </section>
            <section id="whatsapp">
                <Wapp></Wapp>
            </section>
            <EmailSentModal/>
        </EmailSentProvider>
    )
}
export default Home;