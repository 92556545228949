import React, { useState } from 'react';
import { Col, Row, Container, Button, Form, InputGroup } from 'react-bootstrap';
import ContactGif from '../assets/img/contact-gif.gif';
import '../assets/css/style.css';
import '../assets/css/contact.css'
import { api } from '../config/api';
import { useEmailSent } from '../context/EmailSentContext';


function ComponentContact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailResponseLoading, setIsEmailResponseLoading] = useState(false)




  function phoneAuth(event) {
    const inputPhone = event.target.value;
    const formattedPhone = inputPhone
      .replace(/\D/g, '') // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona hífen entre o quinto e o sexto dígitos
    setPhone(formattedPhone);
  }

  const {setShouldShowModal} = useEmailSent()


  const handleFormSubmit = async (event) => {

    try {
      setIsEmailResponseLoading(true)

      await api.post(`/sendEmail`, {
        name,
        email,
        phone,
        message,
        company: 'MGA'
      })
      setShouldShowModal(true)
      setName("")
      setEmail("")
      setPhone("")
      setMessage("")
    } catch (error) {
      console.log(error)
    }
    finally {
      setIsEmailResponseLoading(false)
    }
  };


  return (
    <Container>
      <div id="contact-x">
        <Row>
          <Col lg="7">
            <div id="contact-title">
              <h1>Contato</h1>
              <div id="contact-line"></div>
            </div>
            <div id="contact-content">
              <p>Entre em contato conosco para esclarecer qualquer dúvida. Nosso número de contato está disponível logo abaixo.</p>
            </div>
            <Form id="form" className="form" method='POST' onSubmit={handleFormSubmit}>
              <InputGroup className="mb-3">
                <Form.Control
                  autoComplete='off'
                  maxLength={80}
                  minLength={3}
                  type="text"
                  value={name}
                  placeholder="Nome"
                  onChange={(event) =>
                    setName(event.target.value)
                  }
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  autoComplete='off'
                  maxLength={60}
                  className="input"
                  type='email'
                  value={email}
                  placeholder="Email: exemplo@exemplo.com"
                  onChange={(event) =>
                    setEmail(event.target.value)
                  }
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  autoComplete='off'
                  maxLength={15}
                  minLength={15}
                  mask="(99) 99999-9999"
                  className="input"
                  type="tel"
                  placeholder="Telefone: (11) 99999-9999"
                  value={phone}
                  onChange={phoneAuth}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  autoComplete='off'
                  maxLength={300}
                  minLength={25}
                  id='message'
                  className="input"
                  as="textarea"
                  rows={3}
                  placeholder="Digite aqui a sua mensagem: (max 300)"
                  value={message}
                  onChange={(event) =>
                    setMessage(event.target.value)
                  }
                />
              </InputGroup>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <Button
                  id="button-form"
                  onClick={handleFormSubmit}
                  type='button'
                  disabled={isEmailResponseLoading}
                >
                  {isEmailResponseLoading ? 'Enviando...' : 'Enviar'}

                </Button>
              </div>
            </Form>


          </Col>
          <Col sm="5">
            <div id="gif">
              <img src={ContactGif} alt="" />
            </div>
          </Col>
        </Row>
      </div>
      <div id="contact2">
        <h2>Contato comercial</h2>
        <ul>
          <li>Marcos Puton</li>
          <li>+55 41 8430-6070</li>
          <li>contato@mga.com.br</li>
        </ul>
      </div>
    </Container>
  )
}

export default ComponentContact;

