import '../assets/css/style.css'
import '../assets/css/operations.css'
import React from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


function ComponentOperations(){
    return(
        <Container>
            <div id="operations-x1">
                <div id="operation-title">
                    <h1>Nossas Operações</h1>
                    <div id="operation-line"></div>
                </div>
                <div id="operation-content">
                    <CardGroup>
                        <Row id="one">    
                            <Col id="col-one" xl="5" xs="12"> 
                                <Card id="card-1">
                                    <Card.Body>
                                        <div id="card-body">
                                            <Card.Title>Movimentação interna de contêineres</Card.Title>
                                            <Card.Text>A MGA é especializada na movimentação interna de contêineres no Porto de Paranaguá, assegurando uma logística eficiente e segura desde a descarga até a carga, garantindo o manuseio adequado dessas unidades de transporte.</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col id="col-two" xl="5" xs="12">
                                <Card id="card-2">
                                    <Card.Body>
                                        <div id="card-body">
                                            <Card.Title>Carga geral para embarque de açúcar</Card.Title>
                                            <Card.Text>Além da movimentação de contêineres, a MGA realiza operações específicas para o transporte de carga geral, como o embarque de açúcar. Isso envolve a transferência eficaz do açúcar dos armazéns para os navios, garantindo a integridade da carga e a conformidade com os requisitos de embarque.</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col id="col-three">
                                <Card id="card-3">
                                    <Card.Body>
                                        <div id="card-body">
                                            <Card.Title>Operações integradas no porto de paranaguá</Card.Title>
                                            <Card.Text>A MGA atua de maneira abrangente no Porto de Paranaguá, englobando operações diversas, desde a descarga eficiente de contêineres diretamente dos navios até a gestão cuidadosa da carga geral, como o transporte de açúcar. Essa abordagem integrada destaca a capacidade da MGA em lidar com múltiplas facetas logísticas no ambiente portuário.</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </CardGroup>
                </div>
            </div>
        </Container>    
    )
}
export default ComponentOperations;