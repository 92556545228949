import '../assets/css/style.css'
import '../assets/css/services.css'
import React from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
/* import meta from '../assets/img/meta.gif' */
import missao from '../assets/img/mission.gif'
import CardGroup from 'react-bootstrap/CardGroup';

function ComponentServices(){
    return(
        <Container>
            <div id="services-x">
                <div id="services-title">
                    <h1>Serviços</h1>
                    <div id="services-line"></div>
                </div>
                <div id="services-content">
                    <p>Explore os serviços que oferecemos e compreenda a amplitude e qualidade do que proporcionamos.</p>
                </div>
                <div id="card-group">
                    <CardGroup id="card-group-1">
                            <Card id="card-1">
                                <Card.Body>
                                    <div id="card-body">
                                        <Card.Title>Transporte rodoviário de carga</Card.Title>
                                        <Card.Text>A MGA é especialista no serviço de transporte rodoviário de carga, oferecendo soluções eficientes e confiáveis para o deslocamento de mercadorias. Com uma frota moderna e equipes altamente qualificadas, garantimos a entrega segura e pontual de diferentes tipos de carga, exceto produtos perigosos e mudanças. Nossos serviços abrangem operações intermunicipais, interestaduais e internacionais, proporcionando uma logística adaptável e personalizada para atender às necessidades específicas de cada cliente.</Card.Text>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card id="card-2">
                                <Card.Body>
                                    <div id="card-body">
                                        <Card.Title>Transporte intermunicipal, interestadual e internacional</Card.Title>
                                        <Card.Text>Os serviços de transporte da MGA abrangem operações intermunicipais, interestaduais e internacionais, oferecendo soluções logísticas abrangentes e eficientes para atender às diversas necessidades dos clientes. Seja para deslocamento de mercadorias entre municípios, estados ou países, nossa equipe especializada assegura a entrega segura e pontual, destacando-se pela flexibilidade operacional e pela capacidade de adaptar-se às particularidades de cada trajeto.</Card.Text>
                                    </div>
                                    </Card.Body>
                            </Card>
                            <Card id="card-3">
                                <Card.Body>
                                    <div id="card-body">
                                        <Card.Title>Transferência de cargas gerais para embarque</Card.Title>
                                        <Card.Text>A MGA oferece serviços especializados na transferência de cargas gerais, como o transporte de açúcar, dos armazéns para o embarque nos navios. Nossas operações garantem a integridade da carga durante todo o processo, desde a coleta até a entrega no destino final. Com atenção aos detalhes e cuidado, asseguramos que a carga seja movimentada de forma eficiente, atendendo aos requisitos específicos de embarque.</Card.Text>
                                    </div>
                                    </Card.Body>
                            </Card>
                    </CardGroup>
                </div>
            </div>
        </Container>
    )
}
export default ComponentServices;