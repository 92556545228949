import '../assets/css/style.css'
import '../assets/css/footer.css'
import React from 'react'
import logo from '../assets/img/logo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
/* import Container from 'react-bootstrap/Container';
 */function ComponentFooter(){
    return(
/*          <Container>
 */            <footer>
                <Row className='justify-content-center' id="footer-1">
                    <Col lg="3" xs="12" md='8'>
                        <div className="footer-x1">
                            <div className="logo">
                                <a href="#header"><img src={logo} alt="" /></a>
                            </div>
                            <div className="text-logo">
                                <p>Conduzindo o futuro da logística com eficiência e comprometimento: MGA, sua parceira confiável.</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="2" xs="12" md="5">
                        <div className="footer-x2">
                            <ul>
                                <h2>Links</h2>
                                <div className="text-underlined-footer">
                                    <li>
                                        <a href="#header">Início</a>
                                    </li>
                                </div>
                                <div className="text-underlined-footer">
                                    <li>
                                        <a href="#about">Sobre</a>
                                    </li>
                                </div>
                                <div className="text-underlined-footer">
                                    <li>
                                        <a href="#services">Serviços</a>
                                    </li>
                                </div>
                                <div className="text-underlined-footer">
                                    <li>
                                        <a href="#contact">Contato</a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="2" xs="12" md="5">
                        <div className="footer-x3">
                            <ul>
                                <h2>Serviços</h2>
                                <li>Transporte rodoviário de carga.</li>
                                <li>Transporte intermunicipal, <br /> interestadual e internacional.</li>
                                <li>Transferência de cargas <br /> gerais para embarque.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="2" xs="12" md="5">
                        <div className="footer-x4">
                            <h2>Endereço</h2>
                            <p>Paranaguá - PR</p>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-around' id="footer-2">
                    <p>Todos os Direitos Reservados | Desenvolvido por <a href="https://singlec.com.br/" target="_blank" rel='noreferrer'>Singlec</a></p>
                </Row>
            </footer>
/*          </Container>
 */    )
}
export default ComponentFooter;

