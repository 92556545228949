import '../assets/css/style.css';
import '../assets/css/whatsapp.css';
import React from 'react'
import Wappimg from '../assets/img/wapp.png'

function Wapp() {
    return(
            <div id="wapp">
                <a href="https://web.whatsapp.com/send?phone=554184306070" target="_blank" rel='noreferrer'>
                    <img src={Wappimg} alt="" />
                </a>
            </div>
    )
}
export default Wapp;
