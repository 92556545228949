import '../assets/css/style.css';
import '../assets/css/nav.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/img/logo.png';
import {useState, useEffect} from 'react'

function ComponentNav() {
  const [headerBackground, setHeaderBackground] = useState('transparent');

  function handleScroll() {
    if (window.scrollY > 400) {
      setHeaderBackground('#ff770094'); // altere a cor de fundo para azul, por exemplo
    } else {
      setHeaderBackground('transparent'); // mantenha a cor de fundo transparente
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ backgroundColor: headerBackground, width: '100%', transition: 'background-color 0.5s ease-in-out'}}>
    <Navbar expand="md">
      <Container id="cont">
        <Navbar.Brand id="logoMGA" href="#header"><img src= {logo} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" id="menu">
            <Nav.Link href="#header">
              <div className="text-underlined-nav">
                Início
              </div>
            </Nav.Link>
            <Nav.Link href="#about">
              <div className="text-underlined-nav">
                Sobre
              </div>
            </Nav.Link>
            <Nav.Link href="#services">
              <div className="text-underlined-nav">
                Serviços
              </div>
            </Nav.Link>
            <Nav.Link href="#contact">
              <div className="text-underlined-nav">
                Contato
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
export default ComponentNav;