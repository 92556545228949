import '../assets/css/style.css';
import '../assets/css/about.css'
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import AboutImg from '../assets/img/about-img.png'

function About(){
    return(
        <Container>
            <div id="about1">
                <Row>
                    <Col lg="5" id="col1">
                        <div id="about-x1">
                            <div id="about-title">
                                <h1>Quem somos</h1>
                                <div id="about-line"></div>
                            </div>
                            <div id="about-content">
                                <p>
                                    A MGA se destaca como uma empresa líder no transporte rodoviário de carga, oferecendo serviços abrangentes e 
                                    eficientes que abrangem operações intermunicipais, interestaduais e internacionais. Nosso compromisso inabalável 
                                    com a excelência logística se reflete na expertise que desenvolvemos ao longo dos anos.
                                </p>
                                <p>
                                    A transparência é um pilar fundamental em nossos serviços. Oferecemos uma comunicação direta e clara, proporcionando 
                                    aos nossos clientes visibilidade total do processo logístico. Seja para operações locais, nacionais ou internacionais, 
                                    adaptamos nossos serviços para atender às necessidades específicas de cada cliente.
                                </p>
                                <p>
                                    Além disso, a MGA está preparada para lidar com a movimentação interna de contêineres, garantindo uma gestão eficiente 
                                    desde a descarga até a carga diretamente dos navios no Porto de Paranaguá. Para cargas gerais, como o transporte de açúcar, 
                                    oferecemos operações especializadas, assegurando que a carga seja transferida com segurança dos armazéns para o embarque nos navios.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="7" id="col2">
                        <Row>
                            <div id="about-image-1">
                                {<img src={AboutImg} alt="" />}
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    )   
}
export default About;