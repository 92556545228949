import '../assets/css/style.css';
import '../assets/css/header.css'
import Nav from "../components/Nav"
import Carousel from "../components/Carousel"

function Header() { 
    return(
        <header>
            <div className="d-flex justify-content-around py-3" id="nav" position="fixed">
                <Nav></Nav>
            </div>
            <div className="justify-content-center py-0" id="carousel">
                <Carousel></Carousel>
            </div>
        </header>
    )
}
export default Header;